
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow, 
  IonLoading, 
  IonRefresher, 
  IonRefresherContent 
} from '@ionic/vue';

import { Capacitor } from '@capacitor/core';

import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'
// @ts-ignore
import viewHelperMethods from '@/helper/view'

// @ts-ignore
import * as api from '@/service/index.js'
import { AxiosError, AxiosResponse } from 'axios';

export default  {
  name: 'NewsFeed',
  components: { 
    Header,
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading, 
    IonRefresher, 
    IonRefresherContent
  },
  data () {
    return {
      start: 1,
      isLoading: false,
      totalEntries: 0,
      entries: [],
      articleOpen: false,
      topnews: {},
      blogEntries: [],
      lastScrollLoad: 0,
      lastScrollTop: 0,
      isScrollingDown: false,
      firstLoadDone: false,
      employeurCampaign: {
        active: false,
        text: '',
        url: '',
        color: 'blue',
        target: ''
      }  
    }
  },
  computed: {
    showCampaignTeaser (): boolean {
      // return false
      return (this as any).employeurCampaign.active
    },
    showLoadMore (): boolean {
      return (this as any).totalEntries > (this as any).entries.length
    },
    showNoEntries (): boolean {
      return !(this as any).isLoading && (this as any).firstLoadDone && !(this as any).entries.length 
    },
    hasTopNews (): boolean {
      return !!(this as any).topnews.id;
    },
    gridMarginTop (): string {
      if (localStorage.getItem('jwt') !== null) {
        return !this.hasTopNews ? 'margin-top: -15px' : ''
      }

      return !this.hasTopNews ? 'margin-top: 0' : ''
    },
    isAuthenticated (): boolean {
      return localStorage.getItem('jwt') !== null ? true : false
    }
  },
  methods: {
    ...filterHelperMethods,
    ...viewHelperMethods,
    onScroll (event: any) {
      const vm = (this as any);

      if (event.detail.scrollTop > vm.lastScrollTop) {
        vm.isScrollingDown = true
      }
      // vm.lastScrollTop = event.detail.scrollTop

      let shouldLoad = vm.shouldLoadMoreBasedOnScrollPosition();
      if (shouldLoad && !vm.isLoading && 
        event.detail.scrollTop > (vm.lastScrollLoad + (document.body.clientHeight / 2))
      ) {
          vm.lastScrollLoad = event.detail.scrollTop;
          console.log('loading new content due to scroll position');
          vm.loadMore(null, true);
        }
    },
    openNewsDetailPage (id: number) {
      (this as any).articleOpen = true;
      (this as any).$router.push({ path: `/news/${id}` });
    },
    show (entry: any) {
      if (typeof entry !== 'undefined' && [0, 1, 2].includes(entry?.availableArea)) {
        /**
         * availableArea
         * 0: internal only
         * 1: all (internal + external)
         * 2: external only
         */
        if (localStorage.getItem('jwt') !== null) {
          return entry.availableArea === 0 || entry.availableArea === 1 ? true : false
        } else {
          return entry.availableArea === 2 || entry.availableArea === 1 ? true : false
        }
      } else {
        return false
      }
    },
    goToBlogPage () {
      (this as any).$router.push({ path: `/blog` });
    },
    reset () {
      (this as any).start = 1;
      (this as any).isLoading = false;
      (this as any).totalEntries = 0;
      (this as any).entries = []; 
      (this as any).articleOpen = false;
      (this as any).topnews = {};
      (this as any).blogEntries = [];
      (this as any).lastScrollLoad = 0;
      (this as any).lastScrollTop = 0;
      (this as any).isScrollingDown = false;
      (this as any).firstLoadDone = false;
      (this as any).employeurCampaign = {
        active: false,
        text: '',
        url: '',
        color: 'blue',
        target: ''
      } 
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    },
    async loadMore (event: any, loadOnClick: any) {
      console.log('start load');
      const vm = (this as any);

      if (loadOnClick) {
        console.log('loading new content due to user interaction');
      } else {
        vm.setLoading(true);
      }

      if (loadOnClick !== true) {
        try {
          const topnews = await api.getTopNews();
          console.log(topnews);

          if (topnews.data.id) {
            if (localStorage.getItem('jwt') !== null) {
              vm.topnews = topnews.data;
            } else if (topnews.data.publicTopNews === true) {
              vm.topnews = topnews.data;
            }
          } else {
            if (localStorage.getItem('jwt') !== null) {
              const blogEntries = await api.getBlogFeed(1);
              console.log(blogEntries);

              if (blogEntries.data.entries.length) {
                let i;
                let maxItems = blogEntries.data.entries.length > 3 ? 3 : blogEntries.data.entries.length
                for (i = 0; i < maxItems; i++) {
                  if (blogEntries.data.entries[i].id) {
                    vm.blogEntries.push(blogEntries.data.entries[i]);
                  }
                }
              }
            }
          }
        } catch (error) {
          vm.handleAxiosError(error);
          console.log(error.message);
          if (localStorage.getItem('jwt') !== null) {
            try {
              const blogEntries = await api.getBlogFeed(1);
              console.log(blogEntries);

              if (blogEntries.data.entries.length) {
                let i;
                for (i = 0; i < 3; i++) {
                  vm.blogEntries.push(blogEntries.data.entries[i]);          
                }
              }
            } catch (error) {
              vm.handleAxiosError(error);
              console.log(error.message);
            }
          }
        }

        api.getEmployeurCampaign()
          .then((response: AxiosResponse) => {
            console.log('campaign', response);
            if (response.status === 200 && response.data?.button.id && response.data?.button.enabled) {
              const platform = Capacitor.getPlatform()

              vm.employeurCampaign = {
                active: true,
                text: response.data.button.text,
                url: response.data.button.url,
                color: response.data.button.color,
                target:  platform !== 'ios' && platform !== 'android' ? '_blank' : ''
              }
            }
          })
          .catch((error: AxiosError) => {
            console.log('campaign', error);
          })
      }

      try {
        const data = await api.getNewsFeed(vm.start);
        console.log(data);

        if (data.data.entries.length) {
          for (let j in data.data.entries) {
            if (!data.data.entries[j].internalTopNews && !data.data.entries[j].publicTopNews) {
              vm.entries.push(data.data.entries[j]);
            }        
          }
          vm.totalEntries = data.data.totalEntries;
          vm.start += 1;
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      console.log('end load');
      console.log(vm.entries);
      console.log(vm.totalEntries);
      vm.setLoading(false); 
      vm.firstLoadDone = true;

      if (event?.type === 'ionrefresh') {
        event.target.complete();
      }
    },
    async doRefresh (event: any) {
      if (!(this as any).isLoading) {
        (this as any).reset();
        (this as any).loadMore(event);
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave news feed');
    if ((this as any).articleOpen === true) {
      console.log('opened article');
    } else {
      // will change to different tab view
      (this as any).reset();
    }
  },
  ionViewDidEnter () {
    console.log('[ionView] entered news feed');
    if ((this as any).articleOpen === true) {
      (this as any).articleOpen = false;
      console.log('closed article');
    } else {
      // entered from different tab view
      (this as any).doRefresh();
    }
  },
  mounted () {
    const vm = (this as any);
    vm.doRefresh();

    vm.$bus.on('appStateChanged', (isActive: boolean) => {
      console.log('caught global app state change event', isActive);
      if (isActive) {
        // vm.scrollTop();
        vm.doRefresh();
      }
    });
  }
}
