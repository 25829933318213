<template>
  <ion-page :class="{'campaign-active': showCampaignTeaser}">
    <Header name="UKK Aktuell" />
    <div 
      v-if="showCampaignTeaser"
      class="campaign-teaser"
    >
      <a 
        :href="employeurCampaign.url" 
        role="button"
        class="fab"
        :class="{ 'btn-green': employeurCampaign.color === 'green', 'minimized': isScrollingDown }"
        :target="employeurCampaign.target"
      >
        <span>{{ employeurCampaign.text }}</span>
        <img v-if="employeurCampaign.color === 'blue'" src="@/assets/img/INTRANET-cta-info.svg" width="42">
        <img v-else src="@/assets/img/INTRANET-cta-info-blue.svg" width="42">
      </a>
    </div>
    <ion-content 
      :fullscreen="true"
      :scroll-events="true"
      @ionScroll="onScroll($event)"
    >
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div id="container">

        <ion-loading
          :is-open="isLoading"
          message="Lade Daten.."
          @onDidDismiss="setLoading(false)"
          :duration="3000"
        >
        </ion-loading>

        <div 
          v-if="hasTopNews" 
          class="topnews"
          @click="openNewsDetailPage(topnews.id)"
        >
          <div v-if="topnews?.imageUrl?.length" class="image">
            <img :src="topnews.imageUrl" width="100%" height="100%" />
          </div>
          <div class="article">
            <div class="text">
              <h3 class="font-face--merrisans light">{{ topnews.title }}</h3>
            </div>
            <div class="meta font-face--merrisans light">
              <time>{{ getFormattedDate(topnews.datetime) }}</time>
              <span v-if="topnews.subtitle !== null">
                &nbsp; | &nbsp; {{ topnews.subtitle }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="!hasTopNews && !isLoading" class="blog-teaser">
          <div v-if="isAuthenticated">
            <div class="blog-header" :style="{ backgroundImage: 'url(' + require('@/assets/img/blog-header.png') + ')' }">
              <h1 class="font-face--merrisans light">Newsblog zur Corona-Pandemie</h1>
            </div>
            <div 
              v-if="blogEntries.length"
              class="posts"
              @click="goToBlogPage()"
            >
              <div 
                class="post"
                v-for="(post, index) in blogEntries" 
                :key="index"
              >
                <div class="content">
                  <div class="text">
                    <h3 class="font-face--merrisans light">{{ post.title }}</h3>
                  </div>
                </div>
                <div class="meta">
                  <time class="font-face--merrisans light">{{ getFormattedDate(post.datetime) }}</time>
                </div>
              </div>
              <div class="more">
                <router-link to="/blog" class="font-face--brand btn btn-secondary btn-next">
                  <span>Mehr erfahren</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <ion-grid :style="gridMarginTop">

          <ion-row v-if="showNoEntries" class="no-data">
            <ion-col>
              <div class="card">
                <h3>Keine Einträge</h3>
              </div>
            </ion-col>
          </ion-row>

          <div v-else>
            <ion-row 
              v-for="(entry, index) in entries" 
              :key="index"
              @click="openNewsDetailPage(entry.id)"
            >
              <ion-col v-if="show(entry)">
                <div class="card news-entry">
                  <div class="content">
                    <div v-if="hasImage(entry)" class="image">
                      <img :src="getImageUrl(entry.imageUrl)" width="120" height="90" />
                    </div>
                    <div class="text">
                      <h3 class="font-face--merrisans light" v-html="entry.title"></h3>
                    </div>
                  </div>
                  <div class="meta">
                    <time class="font-face--merrisans light">{{ getFormattedDate(entry.datetime) }}</time>
                    <span v-if="entry.subtitle !== null" class="font-face--merrisans light">
                      &nbsp; | &nbsp; {{ entry.subtitle }}
                    </span>
                  </div>
                </div>
              </ion-col>
            </ion-row>

            <ion-row 
              v-if="showLoadMore" 
              class="load-more"
              :class="{'invisible': isLoading}"
            >
              <ion-col>
                <a href="#" class="btn btn-next btn-secondary" role="button" @click.prevent="loadMore(null, true)">
                  <span>Mehr laden</span>
                </a>
              </ion-col>
            </ion-row>
          </div>

        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow, 
  IonLoading, 
  IonRefresher, 
  IonRefresherContent 
} from '@ionic/vue';

import { Capacitor } from '@capacitor/core';

import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'
// @ts-ignore
import viewHelperMethods from '@/helper/view'

// @ts-ignore
import * as api from '@/service/index.js'
import { AxiosError, AxiosResponse } from 'axios';

export default  {
  name: 'NewsFeed',
  components: { 
    Header,
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading, 
    IonRefresher, 
    IonRefresherContent
  },
  data () {
    return {
      start: 1,
      isLoading: false,
      totalEntries: 0,
      entries: [],
      articleOpen: false,
      topnews: {},
      blogEntries: [],
      lastScrollLoad: 0,
      lastScrollTop: 0,
      isScrollingDown: false,
      firstLoadDone: false,
      employeurCampaign: {
        active: false,
        text: '',
        url: '',
        color: 'blue',
        target: ''
      }  
    }
  },
  computed: {
    showCampaignTeaser (): boolean {
      // return false
      return (this as any).employeurCampaign.active
    },
    showLoadMore (): boolean {
      return (this as any).totalEntries > (this as any).entries.length
    },
    showNoEntries (): boolean {
      return !(this as any).isLoading && (this as any).firstLoadDone && !(this as any).entries.length 
    },
    hasTopNews (): boolean {
      return !!(this as any).topnews.id;
    },
    gridMarginTop (): string {
      if (localStorage.getItem('jwt') !== null) {
        return !this.hasTopNews ? 'margin-top: -15px' : ''
      }

      return !this.hasTopNews ? 'margin-top: 0' : ''
    },
    isAuthenticated (): boolean {
      return localStorage.getItem('jwt') !== null ? true : false
    }
  },
  methods: {
    ...filterHelperMethods,
    ...viewHelperMethods,
    onScroll (event: any) {
      const vm = (this as any);

      if (event.detail.scrollTop > vm.lastScrollTop) {
        vm.isScrollingDown = true
      }
      // vm.lastScrollTop = event.detail.scrollTop

      let shouldLoad = vm.shouldLoadMoreBasedOnScrollPosition();
      if (shouldLoad && !vm.isLoading && 
        event.detail.scrollTop > (vm.lastScrollLoad + (document.body.clientHeight / 2))
      ) {
          vm.lastScrollLoad = event.detail.scrollTop;
          console.log('loading new content due to scroll position');
          vm.loadMore(null, true);
        }
    },
    openNewsDetailPage (id: number) {
      (this as any).articleOpen = true;
      (this as any).$router.push({ path: `/news/${id}` });
    },
    show (entry: any) {
      if (typeof entry !== 'undefined' && [0, 1, 2].includes(entry?.availableArea)) {
        /**
         * availableArea
         * 0: internal only
         * 1: all (internal + external)
         * 2: external only
         */
        if (localStorage.getItem('jwt') !== null) {
          return entry.availableArea === 0 || entry.availableArea === 1 ? true : false
        } else {
          return entry.availableArea === 2 || entry.availableArea === 1 ? true : false
        }
      } else {
        return false
      }
    },
    goToBlogPage () {
      (this as any).$router.push({ path: `/blog` });
    },
    reset () {
      (this as any).start = 1;
      (this as any).isLoading = false;
      (this as any).totalEntries = 0;
      (this as any).entries = []; 
      (this as any).articleOpen = false;
      (this as any).topnews = {};
      (this as any).blogEntries = [];
      (this as any).lastScrollLoad = 0;
      (this as any).lastScrollTop = 0;
      (this as any).isScrollingDown = false;
      (this as any).firstLoadDone = false;
      (this as any).employeurCampaign = {
        active: false,
        text: '',
        url: '',
        color: 'blue',
        target: ''
      } 
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    },
    async loadMore (event: any, loadOnClick: any) {
      console.log('start load');
      const vm = (this as any);

      if (loadOnClick) {
        console.log('loading new content due to user interaction');
      } else {
        vm.setLoading(true);
      }

      if (loadOnClick !== true) {
        try {
          const topnews = await api.getTopNews();
          console.log(topnews);

          if (topnews.data.id) {
            if (localStorage.getItem('jwt') !== null) {
              vm.topnews = topnews.data;
            } else if (topnews.data.publicTopNews === true) {
              vm.topnews = topnews.data;
            }
          } else {
            if (localStorage.getItem('jwt') !== null) {
              const blogEntries = await api.getBlogFeed(1);
              console.log(blogEntries);

              if (blogEntries.data.entries.length) {
                let i;
                let maxItems = blogEntries.data.entries.length > 3 ? 3 : blogEntries.data.entries.length
                for (i = 0; i < maxItems; i++) {
                  if (blogEntries.data.entries[i].id) {
                    vm.blogEntries.push(blogEntries.data.entries[i]);
                  }
                }
              }
            }
          }
        } catch (error) {
          vm.handleAxiosError(error);
          console.log(error.message);
          if (localStorage.getItem('jwt') !== null) {
            try {
              const blogEntries = await api.getBlogFeed(1);
              console.log(blogEntries);

              if (blogEntries.data.entries.length) {
                let i;
                for (i = 0; i < 3; i++) {
                  vm.blogEntries.push(blogEntries.data.entries[i]);          
                }
              }
            } catch (error) {
              vm.handleAxiosError(error);
              console.log(error.message);
            }
          }
        }

        api.getEmployeurCampaign()
          .then((response: AxiosResponse) => {
            console.log('campaign', response);
            if (response.status === 200 && response.data?.button.id && response.data?.button.enabled) {
              const platform = Capacitor.getPlatform()

              vm.employeurCampaign = {
                active: true,
                text: response.data.button.text,
                url: response.data.button.url,
                color: response.data.button.color,
                target:  platform !== 'ios' && platform !== 'android' ? '_blank' : ''
              }
            }
          })
          .catch((error: AxiosError) => {
            console.log('campaign', error);
          })
      }

      try {
        const data = await api.getNewsFeed(vm.start);
        console.log(data);

        if (data.data.entries.length) {
          for (let j in data.data.entries) {
            if (!data.data.entries[j].internalTopNews && !data.data.entries[j].publicTopNews) {
              vm.entries.push(data.data.entries[j]);
            }        
          }
          vm.totalEntries = data.data.totalEntries;
          vm.start += 1;
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      console.log('end load');
      console.log(vm.entries);
      console.log(vm.totalEntries);
      vm.setLoading(false); 
      vm.firstLoadDone = true;

      if (event?.type === 'ionrefresh') {
        event.target.complete();
      }
    },
    async doRefresh (event: any) {
      if (!(this as any).isLoading) {
        (this as any).reset();
        (this as any).loadMore(event);
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave news feed');
    if ((this as any).articleOpen === true) {
      console.log('opened article');
    } else {
      // will change to different tab view
      (this as any).reset();
    }
  },
  ionViewDidEnter () {
    console.log('[ionView] entered news feed');
    if ((this as any).articleOpen === true) {
      (this as any).articleOpen = false;
      console.log('closed article');
    } else {
      // entered from different tab view
      (this as any).doRefresh();
    }
  },
  mounted () {
    const vm = (this as any);
    vm.doRefresh();

    vm.$bus.on('appStateChanged', (isActive: boolean) => {
      console.log('caught global app state change event', isActive);
      if (isActive) {
        // vm.scrollTop();
        vm.doRefresh();
      }
    });
  }
}
</script>

<style lang="less">
.campaign-active .ion-page {
  padding-bottom: 67px !important;
}

.campaign-teaser {
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 15px;
  z-index: 1;
  padding: 0 15px;

  .btn {
    box-shadow: 0 2px 4px rgba(0, 64, 119, .08);
  }

  .btn-green {
    background: #D8F1E5;
    color: var(--text-color-blue);

    span::after {
      background-image: url('~@/assets/img/icons/arrow-next-blue.svg');
    }
  }
}

.fab {
  position: absolute;
  right: 15px;
  bottom: 0px;
  border-radius: 999rem;
  width: calc(100% - 30px);
  height: 50px;
  background: var(--text-color-blue);
  padding: 0.5rem;
  color: white;
  transition: all 0.55s;
  box-shadow: 0 2px 4px rgba(0, 64, 119, .08);

  &.btn-green {
    background: #D8F1E5;

    span {
      color: var(--text-color-blue);
    }
  }
}

.fab span {
    min-height: 50px;
    line-height: 50px;
    font-family: 'PFReminder Pro';
    font-size: 22px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 50px;
    width: calc(100% - 50px);
    opacity: 1;
    transition-delay: 2s;
    transition: all 0.25s;
    text-align: center;
    padding-right: 50px;
}

.fab img {
  width: 42px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
}

.fab.minimized {
  border-radius: 50%;
  width: 50px;
  transition: all 0.55s;
}

.fab.minimized span {
  opacity: 0;
  transition-delay: 2s;
  transition: opacity 1s;
}

.ios-share-hint {
  position: fixed;
  z-index: 9999999;
  color: #fff;
  bottom: 15px;
  width: 80px;
  height: 60px;
  left: calc(50% - 30px);
  animation: bounce 2s infinite;
	-webkit-animation: bounce 2s infinite;
	-moz-animation: bounce 2s infinite;
	-o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0)}	
	40% {-webkit-transform: translateY(-30px)}
	60% {-webkit-transform: translateY(-15px)}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0)}
	40% {-moz-transform: translateY(-30px)}
	60% {-moz-transform: translateY(-15px)}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0)}
	40% {-o-transform: translateY(-30px)}
	60% {-o-transform: translateY(-15px)}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0)}
	40% {transform: translateY(-30px)}
	60% {transform: translateY(-15px)}
}
</style>
<style scoped lang="less">
.load-more {
  margin-top: 15px;
}

.topnews {
  .image {
    width: 100%;
    padding: 0;

    img {
      width: 100%;
      height: auto;
      aspect-ratio: 4 / 3;
      object-fit: contain;
      // object-fit: none;
    }
  }

  .article {
    padding: 15px;
    background: var(--text-color-blue);
    color: #fff;
    margin-top: -3px;

    h3 {
      color: #fff;
      margin-top: 0;
      padding: 0 15px;
      font-size: 18px;
      line-height: 28px;
    }

    .meta {
      border-top: 1px dashed #fff;
      padding: 10px 15px 0;
      font-size: 12px;
      line-height: 20px;;
    }
  }
}

.blog-teaser {
   .posts {
    padding: 0 15px;
    background: #fff;
    color: #000;
    margin-top: -3px;

    h3 {
      color: var(--text-color-grey);
      margin-top: 0;
      padding: 10px 15px 0;
      font-size: 18px;
      line-height: 28px;
    }

    .meta {
      color: var(--text-color-lightgrey);
      border-bottom: 1px dashed #CCC;
      padding: 0 15px 10px;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .more a {
    text-align: right;
    border-color: #fff;
  }
}

.blog-header {
  height: 130px;
  width: 100%;
  margin-top: -20px;
  padding: 20px 30px;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    color: #fff;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    max-width: 250px;
  }
}

.invisible {
  opacity: 0;
}

.hidden {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .topnews {
    .article {
      background: var(--text-color-blue);
    }
  }

  .blog-teaser {
    .posts {
      background: var(--background-darkmode-grey);

      h3 {
        color: #fff;
      }

      .meta {
        border-bottom: 1px dashed #404040;
      }
    }

    .more a {
      border-color: var(--background-darkmode-grey);

      &.btn.btn-secondary.btn-next span::after {
        background-image: url('~@/assets/img/icons/arrow-next-lightblue.svg');
      }
    }
  }
}
body.dark {
  .topnews {
    .article {
      background: var(--text-color-blue);
    }
  }

  .blog-teaser {
    .posts {
      background: var(--background-darkmode-grey);

      h3 {
        color: #fff;
      }

      .meta {
        border-bottom: 1px dashed #404040;
      }
    }

    .more a {
      border-color: var(--background-darkmode-grey);

      &.btn.btn-secondary.btn-next span::after {
        background-image: url('~@/assets/img/icons/arrow-next-lightblue.svg');
      }
    }
  }
}
</style>